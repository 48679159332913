import _get from 'lodash/get';

// TODO: update to TS, and remove 'lodash/get'

// format: YYYY-MM-DD
export const getUserWeddingDate = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].wedding_date');

export const getUserWeddingMonthYearDate = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].wedding_month_year_date');

export const getUserWeddingSlug = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].slug');

export const getUserContext = state => _get(state, 'user.userContext');

export const getUserHasIdentified = state => _get(state, 'user.hasIdentified');

export const getUserRole = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].role');

export const getAccountId = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].account_id');

export const getOwnerFirstName = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].owner_first_name');

export const getOwnerLastName = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].owner_last_name');

export const getPartnerFirstName = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].partner_first_name');

export const getPartnerLastName = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].partner_last_name');

export const getUserWeddingGuestCount = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].guest_count');

export const getUserId = state => _get(state, 'user.userContext.object_id');

export const getOwnerAccountUserId = state => _get(state, 'user.partnerContext[0].userObjectId');

export const getPartnerUserId = state => _get(state, 'user.partnerContext[0].partnerUserObjectId');

export const getRegistryAccountId = state =>
  _get(state, 'user.userContext.registry_account.registry_id');

export const getUserHasRegistry = state => _get(state, 'user.userContext.has_registry', false);

export const getInvitesAccountId = state => _get(state, 'user.userContext.invitation_account.id');

export const getUserBusinessUnitSource = state =>
  _get(state, 'user.userContext.business_unit_source');

export const getUserCreatedAt = state => _get(state, 'user.userContext.created_at');

export const getUserHasCompletedOnboarding = state =>
  _get(state, 'user.userContext.has_completed_onboarding');

export const getUserHasWeddingAccount = state =>
  _get(state, 'user.userContext.has_wedding_account');

export const getUserHasGuestList = state => _get(state, 'user.userContext.has_guest_list');

export const getUserHasInvitationAccount = state =>
  _get(state, 'user.userContext.has_invitation_account');

export const getUserHasOnboardedAlbums = state =>
  _get(state, 'user.userContext.invitation_account.photobooks_onboarding_completed');

export const getUserIsGuest = state => _get(state, 'user.userContext.is_guest', false);

export const getUserHasWebsite = state =>
  _get(state, 'user.userContext.wedding_account.website_onboarding_completed', false);

export const getUserWeddingAccountId = state =>
  _get(state, 'user.userContext.wedding_account.id', false);

export const getUserWeddingCity = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].city');

export const getUserWeddingStateProvince = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0].state_province');

export const getUserWeddingDetails = state =>
  _get(state, 'user.userContext.user_role_account_weddings[0]');

export const getUserEmail = state => _get(state, 'user.userContext.email');

export const getIsFetchingUser = state => _get(state, 'user.busy');

export default {
  getInvitesAccountId,
  getIsFetchingUser,
  getOwnerFirstName,
  getOwnerLastName,
  getPartnerFirstName,
  getPartnerLastName,
  getPartnerUserId,
  getRegistryAccountId,
  getUserContext,
  getUserEmail,
  getUserHasIdentified,
  getUserHasRegistry,
  getUserId,
  getUserIsGuest,
  getUserRole,
  getUserWeddingCity,
  getUserWeddingDate,
  getUserWeddingDetails,
  getUserWeddingGuestCount,
  getUserWeddingStateProvince,
};
